<template lang="pug">

div(:class="{navWrapper: isMobileContext && !profileEditorVisible, openProfileMargin : profileEditorVisible }")
  #menu(:class="{active: isMobileContext && isMobileMenuOpen, 'repair-mode': changeHeaderForRepairMode }")
    header
      agent-editor(v-if="profileEditorVisible"
                  :afterClose="closeProfileEditor"
                  :buttons="['save', 'close']"
                  resource="/settings/agents/profile")
      aiq-menu(:class="{'main-toolbar': !isMobileMenuOpen}",mode="horizontal", menu-trigger="click", ref="headerMenuItem" :ellipsis="false")
        template(v-if="!tutorialInProgress")
          aiq-menu-item.main-toolbar_item(index="1", v-if="canView('/conversations')")
            router-link(v-if="newConversationIndicator", to="/conversations", tag="div", class="unread-message button-glow", @click="onNewConversationIndicatorClick")
              .unread-message-indicator
            router-link(to="/conversations", :class="{'mobileNavItem': isMobileMenuOpen}")
              i.iq-ico-message(:class="{'glow-ico-message': newConversationIndicator}").hidden-xs-only
              | Conversations
          aiq-menu-item.main-toolbar_item(index="2", v-if="canView('/customers')")
            router-link(to="/customers", :class="{'mobileNavItem': isMobileMenuOpen}")
              i.iq-ico-customersdashboard.hidden-xs-only
              | {{ $t('top_nav.customer_tab') }}
          aiq-menu-item.main-toolbar_item(index="3", v-if="canView('/users')")
            router-link(to="/users", :class="{'mobileNavItem': isMobileMenuOpen}")
              i.iq-ico-agentsdashboard.hidden-xs-only
              | Agents
          aiq-menu-item.main-toolbar_item(index="4",  v-show="!isMobileMenuOpen", v-if="canView('/iqtools')")
            router-link(to="/iqtools", :class="{'mobileNavItem': isMobileMenuOpen}")
              i.iq-ico-iqtools.hidden-xs-only
              | IQ Tools
          aiq-menu-item.main-toolbar_item(index="7",  v-show="!isMobileMenuOpen", v-if="canView('/settings')")
            router-link(to="/settings")
              i.iq-ico-settings
              | Settings
          aiq-menu-item.main-toolbar_item(index="8", v-show="!isMobileMenuOpen", v-if="canView('/metrics')")
            router-link(to="/metrics")
              i.iq-ico-metrics
              | Metrics
          aiq-menu-item.main-toolbar_item(index="9", v-show="!isMobileMenuOpen", v-if="canView('/pulse')")
            router-link(to="/pulse/now", :class="{'mobileNavItem': isMobileMenuOpen}")
              i.iq-ico-pulse.hidden-xs-only
              | Pulse
          aiq-menu-item.main-toolbar_item(index="10", v-show="!isMobileMenuOpen", v-if="canView('/reporting')")
            router-link(to="/reporting", :class="{'mobileNavItem': isMobileMenuOpen}")
              i.iq-ico-compliance.hidden-xs-only
              | Reporting

        aiq-menu-item.logo-dropdown-container.hidden-xs-only
          aiq-image.aiq-logo(:src="aiqLogo")

        aiq-menu-item.mobileNavPageName(v-show="!isMobileMenuOpen && isMobileContext")
          span.contextNavText {{contextNavText}}

        aiq-menu-item.main-toolbar_item.agent-status-menu.sub-status(v-show="!isMobileMenuOpen" v-if="FEATURE_FLAGS.SETTINGS_AGENT_STATUS")
          aiq-status-indicator(:status="selectedAgentStatus ? selectedAgentStatus.status : ''" :show-all-states="true")
          template(v-slot:title)
          aiq-tooltip(placement="bottom"
                      effect="dark"
                      :content="$t('top_nav.drop_down.status')"
                      :show-after="500"
                      :disabled="agentStateVisible")
            aiq-select(v-model="selectedAgentStatus"
                      :filterable="false"
                      value-key="id"
                      popper-class="agent-status-dropdown"
                      :teleported="false"
                      size="large"
                      @visible-change="onVisibilityChanged"
                      @click="onAgentStatusClick")
              aiq-option(v-for="item in dropdownStatusOptions",
                        :key="item.id",
                        :label="item.label",
                        :value="item")
                agent-status(:available="item.status" :subStatus="item.subStatus" :show-label="true")

        aiq-menu-item.main-toolbar_item.agent-status-menu(v-show="!isMobileMenuOpen" v-else)
          aiq-status-indicator(:status="selectedAgentStatusOption.field" :show-all-states="true")
          template(v-slot:title)
          aiq-tooltip(placement="bottom"
                      effect="dark"
                      :content="$t('top_nav.drop_down.status')"
                      :show-after="500")
            aiq-select(v-model="selectedAgentStatusOption"
                      :filterable="false"
                      value-key="label"
                      popper-class="agent-status-dropdown"
                      size="large"
                      @visible-change="onVisibilityChanged"
                      @click="onAgentStatusClick")
              aiq-option(v-for="item in agentStatus",
                        :key="item.id",
                        :label="item.label",
                        :value="item")
                i(:class="item.class")
                div.agent-status {{ item.label }}

        aiq-menu-item.icon-container.hidden-xs-only(v-if="canView('/header/lynq_to_button') && !isMobileMenuOpen" @click="showJumpToUI")
          aiq-tooltip(placement="bottom"
                      effect="dark"
                      :content="$t('top_nav.lynq_to_button.tooltip')"
                      :show-after="500")
            fa-icon(:icon="['fal', 'arrow-right-to-bracket']" class='arrow-right')
        aiq-dialog(:title="$t('top_nav.lynq_to_button.dialog_title')"
                   :model-value="showJumpToPopUp"
                   :before-close="handleBeforeClose"
                   :close-on-click-modal="true"
                   :close-on-press-escape="true"
                   :show-close="true")
          .dialog-content {{ $t('top_nav.lynq_to_button.enter_id')}}
          aiq-input(:placeholder="$t('top_nav.lynq_to_button.placeholder')"
                    v-model="jumpToCustomerId"
                    class="lynq-input"
                    type="number"
                    @keyup.enter="goToConversation")
          template(v-slot:footer)
            .dialog-footer
              aiq-button(@click="closeJumpToUI") {{ $t('top_nav.lynq_to_button.cancel')}}
              aiq-button(@click="goToConversation" :disabled="!jumpToCustomerId" type="primary" class="lynq_id_button") {{ $t('top_nav.lynq_to_button.lynq')}}
        aiq-menu-item.icon-container.hidden-xs-only(v-show="!isMobileMenuOpen" v-if="shouldDisplaySchedulingJob" @click="showSchedulingJob")
          aiq-tooltip(placement="bottom"
                  effect="dark"
                  :content="$t('top_nav.drop_down.calendar')"
                  :show-after="500")
            CalendarSvg.calendar

        aiq-menu-item.icon-container.hidden-xs-only(v-if="FEATURE_FLAGS.ENABLE_NOTIFICATION && !isMobileMenuOpen")
          aiq-tooltip(placement="bottom"
                      effect="dark"
                      :content="$t('top_nav.drop_down.notification')"
                      :show-after="500")
            .notification-button(@click="onNotificationClick")
              .notification-badge(v-if="notifications.unread_count > 0")
                aiq-badge(:value="notifications.unread_count")
              NotificationIcon.notification-icon
          dropdown(:options="notifications.items"
                  :show="showNotification"
                  :totalCount="notificationPagination.rowCount"
                  ref="notificationDropdown"
                  @readall="onReadAllNotifications"
                  @delete="onDeleteNotification"
                  @bottom="onNextNotifications"
                  @select="onSelectNotification"
                  @close="onCloseNotification")
        aiq-tooltip(placement="bottom"
                effect="dark"
                :content="$t('top_nav.drop_down.profile')"
                :show-after="500")
          aiq-sub-menu(:index="profileSubmenuIndex"
                      v-show="!isMobileMenuOpen"
                      :teleported="true"
                      v-click-away="onCloseProfileSubMenu"
                      popper-class="agent-profile-dropdown").dropdown
            template(v-slot:title)
              aiq-button
                aiq-image(:src="avatar" :key="avatar")
                span.profile-name {{ firstName }} {{ lastName }}
            aiq-menu-item(:index="profileSubmenuIndex1", @click="showProfileEditor")
              i.iq-ico-my-account
              | {{ $t('top_nav.drop_down.my_account')}}
            aiq-menu-item(@click="showSettings" v-if="shouldDisplaySettingsMenuItem")
              i.el-icon-setting
              | {{ $t('top_nav.drop_down.settings')}}
            aiq-menu-item(@click="redisplayTutorial" v-if="shouldDisplayTutorialMenuItem")
              TutorialIcon
              span.tutorial-text
                | {{ $t('top_nav.drop_down.tutorial')}}
            aiq-menu-item(v-if="shouldDisplayMyPerformance" :index="profileSubmenuIndex2")
              router-link(to="/my/performance")
                i.iq-ico-metrics.grey-icon
                | {{ $t('top_nav.drop_down.my_performance')}}
            aiq-menu-item(:index="profileSubmenuIndex3" v-if="shouldDisplayReleaseNotesLink" @click="openReleaseNotesLink")
                fa-icon(:icon="['far', 'globe-pointer']" size="xl")
                | {{ $t('top_nav.drop_down.lynq_portal')}}
            aiq-menu-item(:index="profileSubmenuIndex4", @click="logout")
              i.iq-ico-logout
              | {{ $t('top_nav.drop_down.logout')}}

      .notification-dialog
        aiq-dialog.modal-editor(:title="notificationMsgTitle",
                                :model-value="!!notificationItemOnPopup"
                                :show-close="false")

          .body
            .customer-reference(v-if="hasCustomerInNotification")
              h5.label Customer
              aiq-button.value(link type="primary" @click="goToCustomerConversation") {{ customerNameInNotification }}
            h5 Message
            br
            p {{notificationMsg}}

          template(v-slot:footer)
            aiq-button(@click="notificationItemOnPopup = null") Close

      aiq-dialog(title="Disable Out of Office"
                :model-value="showVacationAlert"
                width="420px"
                :show-close="false")
        p You are currently away and out of office. <br> Selecting available or busy changes your status, and disables your out of office.
        template(v-slot:footer)
          .dialog-footer
            aiq-button(type="primary" @click="dismissVacationPopup") Stay Away
            aiq-button(@click="onChangeStatus('available')") Make Available
            aiq-button(@click="onChangeStatus('paused')") Make Busy
  #toggle(@click="toogleBurger()")
    #top.span(:class="{active: isMobileMenuOpen}")
    #middle.span(:class="{active: isMobileMenuOpen}")
    #bottom.span(:class="{active: isMobileMenuOpen}")
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import assign from 'lodash/assign';
import { AGENT_STATUS, DEFAULT_SCOPE, DEFAULT_POLL_INTERVAL, FIXED_AGENT_STATUS } from '@/constants';
import { defaultProfile, getCustomerName } from '@/libs';
import aiqLogo from '@/assets/agentiq-logo.png';
import agentEditor from '@/pages/Settings/Agents/children/Manage/Edit.vue';
import AgentStatus from '@/components/AgentStatus.vue';
import TutorialIcon from '@/assets/svg/tutorial.vue';
import NotificationIcon from '@/assets/svg/bell-white.vue';
import Dropdown from '@/components/notificationDropdown.vue';
import { PAGINATION_DEFAULT } from '@/constants/pagination';
import { AGENT_ONBOARDING, SCHEDULE_TYPE, NOTIFICATIONS } from '@/constants/analytics';
import CalendarSvg from '@/assets/svg/calendar-white.vue';
import { confirmBeforeDialogClose } from '@/libs';

const PROFILE_SUBMENU_INDEX = '9';
const PAGINATION_NOTIFICATION_DEFAULT = assign({}, PAGINATION_DEFAULT, { limit: 10 });
const NOTIFICATION_TYPE = {
  CUSTOMER: 'customer',
  AGENT: 'agent',
  SYSTEM: 'system',
};

export default {
  name: 'headerDefault',
  components: {
    agentEditor,
    TutorialIcon,
    NotificationIcon,
    Dropdown,
    CalendarSvg,
    AgentStatus,
  },
  emits: ['changeAvailability', 'logout', 'changeAgentStatus'],
  computed: {
    ...mapState({
      agentProfile: state => state.agent.profile,
      agentConnection: state => state.agent.connection,
      permissions: state => state.agent.permissions,
      newConversationIndicator: state => state.conversations.newConversationIndicator,
      scope: state => state.conversations.scope,
      channel: state => state.conversations.channel,
      tutorialCompletionState: state => state.agent.tutorialCompletionState,
      totalLogins: state => state.agent.profile.totalLogins,
      config: state => state.configs.config,
      notifications: state => state.agent.notifications,
      FEATURE_FLAGS: state => state.featureFlags.FEATURE_FLAGS,
      userGuideConfig: state => state.configs.config.user_guiding,
      isVacationDialogDismissed: state => state.agent.isVacationDialogDismissed,
      isMobileContext: state => state.settings.isMobileContext,
      contextNavText: state => state.settings.contextNavText,
      releaseNotesLink: state => state.configs.releaseNotesLink,
      allAgentStatusOptions: state => get(state, 'settings.agentStatuses.statuses', []),
    }),
    ...mapGetters({
      tutorialInProgress: 'agent/tutorialInProgress',
      isOnVacation: 'agent/isOnVacation',
      isRepairModeEnabled: 'hidden/isRepairMode',
      changeHeaderForRepairMode: 'hidden/changeHeaderForRepairMode',
    }),
    showVacationAlert() {
      return this.isOnVacation && !this.isVacationDialogDismissed;
    },
    shouldDisplaySettingsMenuItem() {
      return false;
    },
    shouldDisplayTutorialMenuItem() {
      return get(this.userGuideConfig, 'menu_to_launch', false);
    },
    shouldDisplaySchedulingJob() {
      return this.FEATURE_FLAGS.SHOW_SCHEDULING;
    },
    shouldDisplayMyPerformance() {
      return this.canView('/my/performance') && this.FEATURE_FLAGS.MY_PERFORMANCE_PAGE;
    },
    shouldDisplayReleaseNotesLink() {
      return this.canView('/help/release_notes') && this.FEATURE_FLAGS.RELEASE_NOTES_LINK;
    },
    agentStatus() {
      const result = {...AGENT_STATUS};
      for (let status in result) {
        if (result[status].id === this.selectedAgentStatusOption.id) {
          delete result[status];
          break;
        }
      }
      return result;
    },
    agentStatusOptions() {
      const result = [];
      for (const agentStatus of this.allAgentStatusOptions) {
        result.push({
          id: agentStatus.status,
          label: agentStatus.label,
          status: agentStatus.status,
          subStatus: '',
        });
        for (const stubStatus of agentStatus.subStatuses) {
          result.push({
            id: `${agentStatus.status}.${stubStatus.status}`,
            label: `${agentStatus.label} | ${stubStatus.label}`,
            status: agentStatus.status,
            subStatus: stubStatus.status,
          });
        }
      }
      return result;
    },
    dropdownStatusOptions() {
      const result = [];
      const selectedStatus = get(this.selectedAgentStatus, 'status');
      const selectedSubStatus = get(this.selectedAgentStatus, 'subStatus');
      for (const agentStatus of this.allAgentStatusOptions) {
        const isStatusSelected = selectedStatus === agentStatus.status && selectedSubStatus === '';
        if (agentStatus.selectable && !isStatusSelected) {
          result.push({
            id: agentStatus.status,
            label: agentStatus.label,
            status: agentStatus.status,
            subStatus: '',
          });
        }
        for (const subStatus of agentStatus.subStatuses) {
          const isSubstatusSelected = agentStatus.status === selectedStatus && selectedSubStatus === subStatus.status;
          if (subStatus.selectable && !isSubstatusSelected) {
            result.push({
              id: `${agentStatus.status}.${subStatus.status}`,
              label: `${agentStatus.label} | ${subStatus.label}`,
              status: agentStatus.status,
              subStatus: subStatus.status,
            });
          }
        }
      }
      return result;
    },
    profileSubmenuIndex() {
      return PROFILE_SUBMENU_INDEX;
    },
    profileSubmenuIndex1() {
      return `${PROFILE_SUBMENU_INDEX}-1`;
    },
    profileSubmenuIndex2() {
      return `${PROFILE_SUBMENU_INDEX}-2`;
    },
    profileSubmenuIndex3() {
      return `${PROFILE_SUBMENU_INDEX}-3`;
    },
    profileSubmenuIndex4() {
      return `${PROFILE_SUBMENU_INDEX}-4`;
    },
    selectedAgentStatusOption: {
      get() {
        const key = get(this, 'agentProfile.available');
        return Object.values(AGENT_STATUS).find(item => item.field === key);
      },
      set(newValue) {
        if (newValue === AGENT_STATUS.Away) {
          this.showAlert().then(data => {
            if (data) {
              this.$emit('changeAvailability', newValue.field);
            }
          });
        } else {
          this.$emit('changeAvailability', newValue.field);
        }
      },
    },
    selectedAgentStatus: {
      get() {
        const status = get(this.agentProfile, 'available');
        const subStatus = get(this.agentProfile, 'busy_status.status');
        const agentStatus = this.agentStatusOptions.find(s => s.status === status && s.subStatus === subStatus);
        if (agentStatus) {
          return agentStatus;
        }
        return this.agentStatusOptions.find(s => s.status === status && s.subStatus === '');
      },
      set(newValue) {
        const newAgentStatus = { value: newValue.status, status: newValue.subStatus };
        const isAlreadyAway = this.selectedAgentStatus.status === FIXED_AGENT_STATUS.away.value;

        if (!isAlreadyAway && newValue.status === FIXED_AGENT_STATUS.away.value) {
          this.showAlert().then(data => {
            if (data) {
              this.$emit('changeAgentStatus', newAgentStatus);
            }
          });
        } else {
          this.$emit('changeAgentStatus', newAgentStatus);
        }
      },
    },
    avatar() {
      return get(this.agentProfile, 'picture.payload.url', defaultProfile.picture);
    },

    firstName() {
      return this.agentProfile.profile.first_name || defaultProfile.first_name;
    },

    lastName() {
      return this.agentProfile.profile.last_name || defaultProfile.last_name;
    },

    hasCustomerInNotification() {
      return Boolean(get(this.notificationItemOnPopup, 'payload.data.customer'));
    },

    customerNameInNotification() {
      return getCustomerName(get(this.notificationItemOnPopup, 'payload.data.customer'));
    },

    notificationMsg() {
      return get(this.notificationItemOnPopup, 'payload.body', '');
    },

    notificationMsgTitle() {
      return get(this.notificationItemOnPopup, 'payload.message', '');
    },
  },
  data() {
    return {
      isLoading: false,
      agentStateVisible: false,
      profileEditorVisible: false,
      aiqLogo,
      showNotification: false,
      poll: null,
      notificationPagination: { ...PAGINATION_NOTIFICATION_DEFAULT },
      notificationItemOnPopup: null,
      isMobileMenuOpen: null,
      showJumpToPopUp: false,
      jumpToCustomerId: '',
    };
  },

  created() {
    if (this.FEATURE_FLAGS.ENABLE_NOTIFICATION) {
      this.getNotifications();
    }
  },

  mounted() {
    if (this.FEATURE_FLAGS.ENABLE_NOTIFICATION) {
      this.startPoll();
    }

    if (this.FEATURE_FLAGS.USE_PUSH_NOTIFICATION) {
      this.startPushListener();
    }

    if (this.FEATURE_FLAGS.SETTINGS_AGENT_STATUS) {
      this.$store.dispatch('settings/getSetting', { name: 'agent_status', stateName: 'agentStatuses' });  
    }
  },

  beforeUnmount() {
    if (this.FEATURE_FLAGS.ENABLE_NOTIFICATION) {
      this.stopPollIfPolling();
    }
  },

  methods: {
    startPushListener() {
      // TODO: we may need to fetch config from backend
      this.$store.dispatch('push/init');
    },

    closeEditor() {
      this.jumpToCustomerId = '';
      this.showJumpToPopUp = false;
    },
    handleBeforeClose() {
      if (!this.jumpToCustomerId) {
        this.closeEditor();
        return;
      }
      confirmBeforeDialogClose(this, this.closeEditor);
    },

    showJumpToUI() {
      this.showJumpToPopUp = true;
    },

    async goToConversation() {
      const customerId = this.jumpToCustomerId;
      this.closeJumpToUI();
      try {
        this.$router.push({
          name: 'ConversationByCustomerId',
          params: { id: customerId },
        });
      } catch (err) {
        this.$aiq.notify.error(`Unable to route to the customer ${customerId}`);
      }
    },

    closeJumpToUI() {
      this.showJumpToPopUp = false;
      this.jumpToCustomerId = '';
    },

    toogleBurger() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    // [Vlad] This is hadrcode, it will be deleted when we will have finished
    isActive(sector) {
      return window.location.href.indexOf(sector) > -1;
    },
    showSchedulingJob() {
      this.$store.dispatch('agent/changeDialogVisibility', { name: 'schedulesDialog', visible: true, type: SCHEDULE_TYPE.CALENDAR });
    },
    redisplayTutorial() {
      this.$store.dispatch('notifications/emitEventToSocket', {
        name: AGENT_ONBOARDING.MANUAL_OPEN,
        payload: {
          agent: this.agentProfile,
          config: this.userGuideConfig,
        },
      });

      this.$store.dispatch('agent/showTutorial');
    },
    logout() {
      this.$emit('logout');
    },
    showAlert(title, text) {
      title = title || 'Status Confirmation';
      text = text || 'Selecting Away unlocks your conversations. <br> Do you want to proceed?';

      return new Promise(resolve => {
        this.$aiq.confirm(title, text, {
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Make Away',
        })
          .then(() => resolve(true), () => resolve(false));
      });
    },

    showProfileEditor() {
      this.$store.dispatch('agents/selectAgent', this.agentProfile)
        .then(() => {
          this.profileEditorVisible = true;
        });
    },

    closeProfileEditor() {
      this.profileEditorVisible = false;
    },

    onNewConversationIndicatorClick() {
      Promise.all([
        this.$store.dispatch('conversations/updateNewConversationIndicator'),
        this.$store.dispatch('conversations/setScope', DEFAULT_SCOPE),
        this.$store.dispatch('conversations/setChannel', 'all'),
      ]).then(() => {
        this.$store.dispatch('conversations/list', {
          channel: 'all', scope: 'mine',
        });
      });
    },
    closeProfileSubmenu() {
      this.$refs.headerMenuItem.close(PROFILE_SUBMENU_INDEX);
    },
    onCloseProfileSubMenu() {
      this.closeProfileSubmenu();
    },
    onVisibilityChanged(val) {
      this.agentStateVisible = val;
    },
    onAgentStatusClick() {
      this.closeProfileSubmenu();
    },
    async onNotificationClick() {
      this.showNotification = !this.showNotification;

      this.$store.dispatch(
        'notifications/emitEventToSocket',
        { name: NOTIFICATIONS.BELL_CLICKED,
          payload: {
            agent: this.$store.getters['agent/profile'],
            received: this.notificationPagination.rowCount,
            unread_count: this.notifications.unread_count,
          },
        },
      );
    },
    onCloseNotification() {
      this.showNotification = false;
    },
    async onSelectNotification(item) {
      const reminder = {
        name: 'selectNotification',
        type: SCHEDULE_TYPE.NOTIFICATIONS,
      };
      await this.$store.dispatch('agent/markReadNotification', { notification: item, reminder });
      await this.getNotifications();
      this.onCloseNotification();

      const notificationType = get(item, 'payload.type');
      if (notificationType === NOTIFICATION_TYPE.CUSTOMER) {
        const customerId = get(item, 'payload.sender_id');
        this.$router.push({
          name: 'ConversationByCustomerId',
          params: { id: customerId },
        });
      } else if (notificationType === NOTIFICATION_TYPE.AGENT) {
        this.notificationItemOnPopup = item;
      }
    },
    async onReadAllNotifications() {
      await this.$store.dispatch('agent/readAllNotification');
      await this.getNotifications();
    },
    async onDeleteNotification(item) {
      const reminder = {
        name: 'deleteNotification',
        type: SCHEDULE_TYPE.NOTIFICATIONS,
      };
      await this.$store.dispatch('agent/deleteNotification', { notification: item, reminder });
    },
    async onNextNotifications({ done, complete }) {
      const { offset, limit, pageCount, rowCount } = this.notificationPagination;
      const newOffset = offset + limit;
      if (newOffset >= rowCount) {
        complete();
        return;
      }

      await this.getNotifications({ offset: newOffset, limit, pageCount, rowCount });

      // ugly handling infinite loading with done and complete callbacks.
      done();
      if (this.notificationPagination.rowCount === this.notifications.items.length) {
        complete();
      }
    },
    startPoll() {
      this.stopPollIfPolling();
      const resetNotifications = async () => {
        try {
          await this.getNotifications();
        } catch (err) {
          this.$aiq.notify.error('Unable to fetch notification');
        }
        if (this.poll) {
          this.poll = setTimeout(resetNotifications, DEFAULT_POLL_INTERVAL);
        }
      };
      this.poll = setTimeout(resetNotifications, DEFAULT_POLL_INTERVAL);
    },

    stopPollIfPolling() {
      if (this.poll) {
        const poll = this.poll;
        this.poll = null;
        clearTimeout(poll);
      }
    },

    async getNotifications(newPagination) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const targetPagination = assign({}, PAGINATION_NOTIFICATION_DEFAULT, newPagination);
      const data = await this.$store.dispatch('agent/getNotifications', targetPagination);
      this.notificationPagination = data.pagination;
      if (!newPagination && this.$refs.notificationDropdown) {
        // reset notifications, so need to reset infinite loading
        this.$refs.notificationDropdown.resetInfiniteLoading();
      }
      this.isLoading = false;
      return data;
    },

    async onChangeStatus(newStatus) {
      this.dismissVacationPopup();
      try {
        await this.$store.dispatch('agent/disableVacationMode');
        if (this.FEATURE_FLAGS.SETTINGS_AGENT_STATUS) {
          this.$emit('changeAgentStatus', { value: newStatus, status: '' });
        } else {
          this.$emit('changeAvailability', newStatus);
        }
        this.$aiq.notify.success('Updated');
      } catch (err) {
        this.$aiq.notify.error('Unable to update it');
      }
    },

    dismissVacationPopup() {
      return this.$store.dispatch('agent/dismissVacationPopUp');
    },

    goToCustomerConversation() {
      const customer = get(this.notificationItemOnPopup, 'payload.data.customer');
      this.notificationItemOnPopup = null;
      if (customer) {
        this.$router.push({
          name: 'ConversationByCustomerId',
          params: { id: customer.id },
        });
      }
    },

    openReleaseNotesLink() {
      const queryString = this.agentConnection ? `?connection=${this.agentConnection}` : '';
      window.open(`${this.releaseNotesLink}${queryString}`, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";
$scale-duration: 1.2;

.contextNavText{
  font-size: 20px;
  font-weight: 100;
  line-height: 17px;
}

a.mobileNavItem {
  padding-top: 25px !important;
  font-size: 20px !important;
  height: 60px !important;
}

.mobileNavPageName{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
    line-height: initial;
    border-bottom: 0;
    justify-content: center;
    flex-grow: 1;
    color: white;
    padding-left: 55px;
}

.aiq-logo {
  width: 93px;
  height: 14px;
  margin-bottom: -3px;
}
.md-theme-agentiq {

  .iq-ico-message.glow-ico-message {
    &::before {
      background: initial;
    }
  }

  .main-toolbar {
    display: flex;
    justify-content: space-between;
    height: 64px;
    min-height: 64px;
    padding: 0;
    padding: 0 30px 0 20px;
    font-weight: 500;
    background-color: $aiq-bgc-header;
  }
  .main-toolbar_item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
    width: 99px;
    justify-content: center;
    line-height: initial;
    border-bottom: 0;

    &:hover {
      background-color: transparent;

      > a {
        opacity: 1;
      }
    }
  }
  .main-toolbar_item > a {
    display: flex;
    flex-flow: column wrap;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    text-align: center;
    opacity: .75;
    width: 100%;
    height: 100%;
    padding-top: 13px;

    [class^="iq-ico"] {
      margin-bottom: 6px;
    }

    &:hover {
      color: #fff;
    }

    &[class*="active"] {
      opacity: 1;
      background-color: rgba(70,113,179,0.4);
    }
  }
  .main-toolbar_item {
    $unread-message-indicator-size: 24px;
    $unread-message-indicator-color: #E65BB3;

    .unread-message {
      width: $unread-message-indicator-size;
      height: $unread-message-indicator-size;
      top: 11px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      &-indicator {
        background: radial-gradient($unread-message-indicator-color, rgba($unread-message-indicator-color, .5));
        width: $unread-message-indicator-size;
        height: $unread-message-indicator-size;
        border-radius: 50%;
        position: absolute;
      }
    }

    @keyframes glowing {
      0% {
        transform: scale($scale-duration);
      }
      40% {
        transform: scale(1);
      }
      60% {
        transform: scale($scale-duration);
      }
      100% {
        transform: scale($scale-duration);
      }
    }

    .button-glow {
      animation: glowing 1500ms infinite;
    }
  }

  .icon-container {
    border-bottom: 0;
    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  .mobileNavPageName {
    border-bottom: 0;
    &:active,
    &:focus,
    &:hover {
      opacity: 1;
      color: white;
      background-color: transparent;
    }
  }

  .logo-dropdown-container {
    min-width: 219px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin-right: 15px;
    border-bottom: 0;
    padding: 0px;
    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  .dropdown {
    margin-left: 15px;

    .profile-name {
      white-space: nowrap;
      overflow: hidden;
      min-width: 0px;
      text-overflow: ellipsis;
      line-height: 28px;
    }

    .el-button {
      margin: 0;
      padding: 0;
      min-width: 0px;
    }
    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
    span {
      margin-left: 8px;
      font-size: 12px;
      line-height: 12px;
      text-transform: none;
    }
  }

  .agent-status-menu {
    justify-content: initial;
    width: 135px;
    margin-right: 0px;

    .status-available {
      &::before {
        margin-top: -5px;
      }
    }
  }

  .sub-status {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 250px;

    :deep(.el-select) {
      .el-select__selection {
        .el-select__placeholder {
          width: 180px;
        }
      }

      .el-select__suffix {
        margin-left: 0 !important;
        width: 20px;
      }
    }
  }
}

.tutorial-text {
  margin-left: 8px;
}

.dialog-content {
    font-size: 14px;
    margin-bottom: 10px;
}

:deep(.lynq-input input::placeholder) {
  font-size: 14px;
}

:deep(.lynq-input input) {
  font-size: 14px;
}


.icon-container {
  position: relative;
  padding: 18px 0px 15px 10px;

  .notification-icon {
    width: 21.78px;
    height: 28px;
    stroke: white;
    margin-bottom: 2px;
  }

  .arrow-right {
    color: white;
    height: 28px;
    margin-top: 3px;
    outline: none;
    box-shadow: none;
  }

  .notification-button {
    cursor: pointer;
  }

  .notification-badge {
    position: absolute;
    right: -5px;
    top: 0px;
  }
}

.calendar-icon {
  font-size: 25px;
  color: white;
  cursor: pointer;
  margin: 3px;
}

.el-icon-time {
  width: 18px;
  margin-right: 8px;
}

.el-icon-setting {
  margin-left: -4px;
}

.customer-reference {
  display: inline-block;
  .label {
    display: inline-block;
    padding: 10px 10px 0px 0px;
  }

  .value {
    float: right;
    padding: 10px 0;
  }
}

.calendar {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.app {

  .logo-header {
    padding-left: 15px;
    padding-right: 50px;
    z-index: 100;
  }

  .navWrapper {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
  }

  #logo.el-menu-item.is-active {
    border-bottom-color: transparent;
  }

  #toggle {
    position: absolute;
    left: 20px;
    top: 14px;
    z-index: 999;
    width: 30px;
    height: 40px;
    cursor: pointer;
    // float: right;
    transition: all .3s ease-out;
    visibility: hidden;
    opacity: 0;
  }

  #toggle .span {
    border-radius: 10px;
    background: white;
    transition: all 0.3s ease-out;
    backface-visibility: hidden;
  }

  #top.span.active {
    transform: rotate(45deg) translateX(3px) translateY(5px) !important;
  }

  #middle.span.active {
    opacity: 0;
  }

  #bottom.span.active {
    transform: rotate(-45deg) translateX(8px) translateY(-10px) !important;
  }

  @media only screen and (max-width: 768px) {

    .openProfileMargin{
      margin-bottom: -64px;
    }

    .logo-header {
      padding: 0;
    }

    #toggle {
      visibility: visible;
      opacity: 1;
      margin-top: 6px;
    }

    #toggle .span {
      height: 4px;
      margin: 5px 0;
      transition: all .3s ease;
      backface-visibility: visible;
      visibility: visible;
      opacity: 1;
    }

    #menu .el-menu-item {
      display: none;
    }

    #menu .el-menu-item.mobileNavPageName {
      display: flex;
    }

    #menu.active {
      visibility: visible;
      opacity: 0.98;
      transition: all .5s ease;

    .el-menu--horizontal .el-menu-item {
    text-align: center;
    float: none;
    display: block;
    height: 100%;
    width: 100%;
    border-top: 1px solid #EAEAEB;
    font-size: 18px;
    }

    .el-menu {
    }


    }
  }

}

.repair-mode {
  .main-toolbar {
    background-color: red;
  }
}

.el-menu--horizontal .el-menu .el-menu-item.is-active {
  color: #9da4b1;

  &:hover {
    color:#409eff;
  }
}

.agent-sub-status {
  display: inline-block;
  margin-left: 7px;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

<style lang="scss">
.icon-container {
  .notification-badge {
    sup.el-badge__content {
      border-color: transparent;
    }
  }
}
</style>
